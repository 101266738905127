import React, { useState, useEffect } from 'react';
import './ExpandableClient.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faRotateRight, faAngleDown, faAngleUp, faArrowUp, faArrowDown, faClipboard, faFileWord } from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';


function stripHtml(html) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;

    return tempDivElement.textContent || tempDivElement.innerText || "";
}

function ExpandableClient({ title, summaryApiUrl, icon, expanded, language }) {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(''); 
    const [isVisible, setIsVisible] = useState(false);
    const [visibleNoteContainer, setVisibleNoteContainer] = useState(null);
    const [data, setData] = useState({
        eihofMueller: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null },
        bergkaesereiAlpenglueck: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null },
        baeckereiSonnenkorn: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null }
    });
    const [gespraechspartner, setGespraechspartner] = useState('');
    const [kundenzufriedenheit, setKundenzufriedenheit] = useState('1');
    const [kommentar, setKommentar] = useState('');
  
    const handleSave = () => {
      setGespraechspartner('');
      setKundenzufriedenheit('1');
      setKommentar('');
    };    
    const { t } = useTranslation();

    useEffect(() => {
        setIsOpen(expanded);
    }, [expanded]);

    const toggle = () => setIsOpen(!isOpen);

    const toggleTextVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleNoteContainerVisibility = (tabName) => {
        setVisibleNoteContainer(visibleNoteContainer === tabName ? null : tabName);
      };

    function refreshContent() {
        setData(prevData => ({
            ...prevData,
            [activeTab]: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null }
        }));
    }

    useEffect(() => {
        if (!activeTab || !isOpen) return;

        const fetchSpeech = async (text) => {
            if (!text) return;
            setData(prevData => ({
                ...prevData,
                [activeTab]: { ...prevData[activeTab], speechLoading: true, speechError: null }
            }));

            try {
                const response = await fetch(`/api/customer_audio?customer=${activeTab}&language=${language}`);
                const data = await response.json();
                const audioDataUrl = `data:audio/mp3;base64,${data.customer_audio}`;
                setData(prevData => ({
                    ...prevData,
                    [activeTab]: { ...prevData[activeTab], speechFile: audioDataUrl, speechLoading: false }
                }));
            } catch (error) {
                setData(prevData => ({
                    ...prevData,
                    [activeTab]: { ...prevData[activeTab], speechLoading: false, speechError: error.message }
                }));
            }
        };

        if (!data[activeTab].summary && isOpen && !data[activeTab].summaryLoading) {
            const fetchSummary = async () => {
                setData(prevData => ({
                    ...prevData,
                    [activeTab]: { ...prevData[activeTab], summaryLoading: true, summaryError: null }
                }));

                try {
                    const summaryResponse = await fetch(`/api/customer_summary?customer=${activeTab}&language=${language}`);
                    const summaryResponseJson = await summaryResponse.json();
                    setData(prevData => ({
                        ...prevData,
                        [activeTab]: { ...prevData[activeTab], summary: summaryResponseJson.customer_summary, summaryLoading: false }
                    }));

                    fetchSpeech(stripHtml(summaryResponseJson.summary));
                } catch (error) {
                    setData(prevData => ({
                        ...prevData,
                        [activeTab]: { ...prevData[activeTab], summaryLoading: false, summaryError: error.message }
                    }));
                }
            };
            fetchSummary();
        }
    }, [activeTab, isOpen, data, summaryApiUrl, language]);

    const handleTabChange = (tab) => {
        if (tab === activeTab) {
            setActiveTab('');
        } else {
            setActiveTab(tab);
        }
    };
    
    const generateWordDocument = async (messages) => {
        try {
            const response = await fetch('/api/generate_word_document', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ messages }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to generate document');
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'client_summary.docx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error generating document:', error);
        }
    };
    
    return (
        <div className="expandable-container">
            <div className="expandable-header" onClick={toggle}>
                <span>
                <FontAwesomeIcon 
                    icon={icon}
                    className='expandable-icon'
                />
                {` ${title}`}
                </span>
                <FontAwesomeIcon 
                    icon={isOpen ? faAngleUp : faAngleDown} 
                    className="arrow" 
                />
            </div>
            <div className={`tab-content ${isOpen ? 'open' : ''}`}>
                {isOpen && (
                    <>
                        <div className="tab-buttons-client">
                            <div className="button-number-wrapper">
                                <button onClick={() => handleTabChange('baeckereiSonnenkorn')} className={`tab-button-client ${activeTab === 'baeckereiSonnenkorn' ? 'active' : ''}`}>Bäckerei Sonnenkorn</button>
                                <div className="number-div">
                                    <FontAwesomeIcon className='arrow-down' icon={faArrowDown}/>- 8 %
                                    <button 
                                        onClick={() => toggleNoteContainerVisibility('baeckereiSonnenkorn')} 
                                        className="new-note-icon"
                                    >
                                        <FontAwesomeIcon 
                                            icon={faClipboard} 
                                        />
                                    </button>
                                </div>
                            </div>
                            {visibleNoteContainer === 'baeckereiSonnenkorn' && (
                                <div className="new-note-container">
                                    <div className="new-note-input">
                                        <label htmlFor="gespraechspartner" style={{ fontSize: '14px' }}>Gesprächspartner</label>
                                        <input 
                                            type="text" 
                                            id="gespraechspartner" 
                                            value={gespraechspartner}
                                            onChange={e => setGespraechspartner(e.target.value)}
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px',
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '5px',
                                                    fontSize: '14px'
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="new-note-input">
                                        <label htmlFor="kundenzufriedenheit" style={{ fontSize: '14px'}}>Kundenzufriedenheit</label>
                                        <div className="select-wrapper" style={{
                                            padding: '16px',
                                            boxSizing: 'border-box',
                                            borderRadius: '10px',
                                            border: '1px solid #6a6a6a',
                                            outline: 'none',
                                            marginTop: '5px',
                                            position: 'relative',
                                        }}>
                                            <select 
                                                id="kundenzufriedenheit" 
                                                value={kundenzufriedenheit}
                                                onChange={e => setKundenzufriedenheit(e.target.value)}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    border: 'None',
                                                    outline: 'None',
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="new-note-input">
                                        <label htmlFor="kommentar" style={{ fontSize: '14px' }}>Kommentar</label>
                                        <textarea 
                                            id="kommentar" 
                                            value={kommentar}
                                            onChange={e => setKommentar(e.target.value)}
                                            rows='3'
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px',
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '5px',
                                                    fontFamily: "Work Sans, sans-serif",
                                                    fontSize: '14px'
                                                }
                                            }
                                        ></textarea>
                                    </div>
                                    <button 
                                        className="new-note-save"
                                        onClick={handleSave}
                                    >
                                        Speichern
                                    </button>
                                </div>
                            )}
                            <div className="button-number-wrapper">
                                <button onClick={() => handleTabChange('bergkaesereiAlpenglueck')} className={`tab-button-client ${activeTab === 'bergkaesereiAlpenglueck' ? 'active' : ''}`}>Bergkäserei Alpenglück</button>
                                <div className="number-div">
                                    <FontAwesomeIcon className='arrow-up' icon={faArrowUp}/>14 %
                                    <button 
                                        onClick={() => toggleNoteContainerVisibility('bergkaesereiAlpenglueck')} 
                                        className="new-note-icon"
                                    >
                                        <FontAwesomeIcon 
                                            icon={faClipboard} 
                                        />
                                    </button>
                                </div>
                            </div>
                            {visibleNoteContainer === 'bergkaesereiAlpenglueck' && (
                                <div className="new-note-container">
                                    <div className="new-note-input">
                                        <label htmlFor="gespraechspartner" style={{ fontSize: '12px' }}>Gesprächspartner</label>
                                        <input 
                                            type="text" 
                                            id="gespraechspartner" 
                                            value={gespraechspartner}
                                            onChange={e => setGespraechspartner(e.target.value)}
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px',
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '3px'
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="new-note-input">
                                        <label htmlFor="kundenzufriedenheit" style={{ fontSize: '12px'}}>Kundenzufriedenheit</label>
                                        <select 
                                            id="kundenzufriedenheit" 
                                            value={kundenzufriedenheit}
                                            onChange={e => setKundenzufriedenheit(e.target.value)}
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px', 
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '3px'
                                                }
                                            }
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div className="new-note-input">
                                        <label htmlFor="kommentar" style={{ fontSize: '12px' }}>Kommentar</label>
                                        <textarea 
                                            id="kommentar" 
                                            value={kommentar}
                                            onChange={e => setKommentar(e.target.value)}
                                            rows="3" 
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px',
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '3px'
                                                }
                                            }
                                        ></textarea>
                                    </div>
                                    <button 
                                        className="new-note-save"
                                        onClick={handleSave}
                                    >
                                        Speichern
                                    </button>
                                </div>
                            )}                            
                            <div className="button-number-wrapper">
                                <button onClick={() => handleTabChange('eihofMueller')} className={`tab-button-client ${activeTab === 'eihofMueller' ? 'active' : ''}`}>Ei-Hof Müller</button>
                                <div className="number-div">
                                    <FontAwesomeIcon className='arrow-up' icon={faArrowUp}/>20 %
                                    <button 
                                        onClick={() => toggleNoteContainerVisibility('eihofMueller')} 
                                        className="new-note-icon"
                                    >
                                        <FontAwesomeIcon 
                                            icon={faClipboard} 
                                        />
                                    </button>
                                </div>
                            </div>
                            {visibleNoteContainer === 'eihofMueller' && (
                                <div className="new-note-container">
                                    <div className="new-note-input">
                                        <label htmlFor="gespraechspartner" style={{ fontSize: '12px' }}>Gesprächspartner</label>
                                        <input 
                                            type="text" 
                                            id="gespraechspartner" 
                                            value={gespraechspartner}
                                            onChange={e => setGespraechspartner(e.target.value)}
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px',
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '3px'
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="new-note-input">
                                        <label htmlFor="kundenzufriedenheit" style={{ fontSize: '12px'}}>Kundenzufriedenheit</label>
                                        <select 
                                            id="kundenzufriedenheit" 
                                            value={kundenzufriedenheit}
                                            onChange={e => setKundenzufriedenheit(e.target.value)}
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px', 
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '3px'
                                                }
                                            }
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div className="new-note-input">
                                        <label htmlFor="kommentar" style={{ fontSize: '12px' }}>Kommentar</label>
                                        <textarea 
                                            id="kommentar" 
                                            value={kommentar}
                                            onChange={e => setKommentar(e.target.value)}
                                            rows="3" 
                                            style={
                                                { 
                                                    width: '100%', 
                                                    padding: '8px', 
                                                    boxSizing: 'border-box', 
                                                    borderRadius: '10px',
                                                    border: '1px solid #6a6a6a',
                                                    outline: 'None',
                                                    marginTop: '3px'
                                                }
                                            }
                                        ></textarea>
                                    </div>
                                    <button 
                                        className="new-note-save"
                                        onClick={handleSave}
                                    >
                                        Speichern
                                    </button>
                                </div>
                            )}                            
                        </div>
                        {activeTab && (
                            data[activeTab].summaryLoading ? (
                                <div>
                                    <div className="speech-loading-message">
                                        <div className="speech-loader"></div> {}    
                                        <div className="speech-loader-text">{t('loadingSummary')}</div>
                                    </div>
                                </div>
                            ) : data[activeTab].speechLoading ? (
                                <div>
                                    <div className="speech-loading-message">
                                        <div className="speech-loader"></div> {}
                                        <div className="speech-loader-text">{t('loadingAudio')}</div>
                                    </div>
                                </div>
                            )
                            : data[activeTab].summaryError ? (
                                <p className="error-message">Fehler: {data[activeTab].summaryError}</p>
                            ) : 
                            (
                                <div className='summary-data'>
                                    <audio controls key={data[activeTab].speechFile} className='audio-playbar'>
                                        <source src={data[activeTab].speechFile} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                <button 
                                    onClick={toggleTextVisibility} 
                                    className="show-text-icon"
                                >
                                    <FontAwesomeIcon 
                                        icon={faFileAlt} 
                                    />
                                </button>
                                <button
                                    className="word-icon" 
                                    onClick={() => generateWordDocument(data[activeTab].summary)}
                                >
                                    <FontAwesomeIcon icon={faFileWord} />
                                </button>                                                              
                                {data[activeTab].summary && (
                                    <button 
                                        onClick={refreshContent} 
                                        className="refresh-content"
                                    >
                                        <FontAwesomeIcon 
                                            icon={faRotateRight} 
                                        />
                                    </button>
                                )}
                                <div className={`data-content ${isVisible ? "data-content-visible" : ""}`} 
                                    dangerouslySetInnerHTML={{ __html: data[activeTab].summary }} />
                                </div>
                            )
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default ExpandableClient;
