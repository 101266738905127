import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    de: {
      translation: {
        "greeting": "Guten Tag, Memo!",
        "monthlyReport": "Erfolgsrechnung Monat",
        "yearlyReport": "Erfolgsrechnung Jahr",
        "bilance": "Bilanz Status",
        "bilance_kpi": "Bilanz KPIs",
        "netRevenue": "Konzern Nettoumsatz",
        "revenue": "Umsatz Geschäftsbereich",
        "margin": "Deckungsbeitrag Monat",
        "productAnalysis": "Produkt Analyse",
        "regionAnalysis": "Region Analyse",
        "myClients": "Meine Kunden",
        "openAll": "Alle öffnen",
        "closeAll": "Alle schliessen",
        "shortSummary": "Kurz",
        "longSummary": "Lang",
        "loadingSummary": "Deine Zusammenfassung wird geladen...",
        "loadingAudio": "Audio wird geladen"
      }
    },
    fr: {
      translation: {
        "greeting": "Bonjour, Memo !",
        "monthlyReport": "Compte de résultat mensuel",
        "yearlyReport": "Compte de résultat annuel",
        "bilance": "Statut du bilan",
        "bilance_kpi": "KPIs du bilan",
        "netRevenue": "Chiffre d'affaires net consolidé",
        "revenue": "Chiffre d'affaires par secteur",
        "margin": "Marge contributive mensuelle",
        "productAnalysis": "Analyse de produit",
        "regionAnalysis": "Analyse régionale",
        "myClients": "Mes clients",
        "openAll": "Ouvrir tout",
        "closeAll": "Fermer tout",
        "shortSummary": "Court",
        "longSummary": "Long",
        "loadingSummary": "Votre résumé est en cours de chargement...",
        "loadingAudio": "Chargement de l'audio en cours",                  
      }
    },
    en: {
      translation: {
        "greeting": "Hi, Memo!",
        "monthlyReport": "Monthly Financial Report",
        "yearlyReport": "Annual Financial Report",
        "bilance": "Balance Status",
        "bilance_kpi": "Balance KPIs",
        "netRevenue": "Group Net Revenue",
        "revenue": "Division Revenue",
        "margin": "Monthly Contribution Margin",
        "productAnalysis": "Product Analysis",
        "regionAnalysis": "Regional Analysis",
        "myClients": "My customers",
        "openAll": "Open All",
        "closeAll": "Close All",
        "shortSummary": "Short",
        "longSummary": "Long",
        "loadingSummary": "Your summary is loading...",
        "loadingAudio": "Loading audio"
      }
    }
  };

  

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
