import React, { useState, useEffect } from 'react';
import './Expandable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';

function getSummaryApiUrl(apiUrl, activeTab) {
    return apiUrl + (activeTab === 'kurz' ? '&summary_length=short_summary' : '&summary_length=long_summary');
}

function stripHtml(html) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;

    return tempDivElement.textContent || tempDivElement.innerText || "";
}

function Expandable({ title, summaryApiUrl, icon, expanded, language }) {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('kurz');
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState({
        kurz: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null },
        lang: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null }
    });
    const { t } = useTranslation();
    useEffect(() => {
        setIsOpen(expanded);
    }, [expanded]);

    const toggle = () => setIsOpen(!isOpen);

    const toggleTextVisibility = () => {
        setIsVisible(!isVisible);
    };

    function refreshContent() {
        setData(prevData => ({
            ...prevData,
            [activeTab]: { summary: null, summaryLoading: false, summaryError: null, speechFile: null, speechLoading: false, speechError: null }
        }));
    }

    useEffect(() => {
        const fetchSpeech = async (text) => {
            if (!text) return;
            setData(prevData => ({
                ...prevData,
                [activeTab]: { ...prevData[activeTab], speechLoading: true, speechError: null }
            }));

            try {
                const response = await fetch(`/api/fetch_speech_from_text?text=${encodeURIComponent(text)}&language=${language}`);
                const data = await response.json();
                const audioDataUrl = `data:audio/mp3;base64,${data.speech_file}`;
                setData(prevData => ({
                    ...prevData,
                    [activeTab]: { ...prevData[activeTab], speechFile: audioDataUrl, speechLoading: false }
                }));
            } catch (error) {
                setData(prevData => ({
                    ...prevData,
                    [activeTab]: { ...prevData[activeTab], speechLoading: false, speechError: error.message }
                }));
            }
        };

        if (!data[activeTab].summary && isOpen && !data[activeTab].summaryLoading) {
            const fetchSummary = async () => {
                setData(prevData => ({
                    ...prevData,
                    [activeTab]: { ...prevData[activeTab], summaryLoading: true, summaryError: null }
                }));

                try {
                    const summaryResponse = await fetch(getSummaryApiUrl(summaryApiUrl, activeTab));
                    const summaryResponseJson = await summaryResponse.json();
                    setData(prevData => ({
                        ...prevData,
                        [activeTab]: { ...prevData[activeTab], summary: summaryResponseJson.summary, summaryLoading: false }
                    }));

                    fetchSpeech(stripHtml(summaryResponseJson.summary));
                } catch (error) {
                    setData(prevData => ({
                        ...prevData,
                        [activeTab]: { ...prevData[activeTab], summaryLoading: false, summaryError: error.message }
                    }));
                }
            };
            fetchSummary();
        }
    }, [activeTab, isOpen, data, summaryApiUrl ]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    
    return (
<div className="expandable-container">
  <div className="expandable-header" onClick={toggle}>
            <span>
            <FontAwesomeIcon 
                icon={icon}
                className='expandable-icon'
            />
            {` ${title}`}
            </span>
            <FontAwesomeIcon 
                icon={isOpen ? faAngleUp : faAngleDown} 
                className="arrow" 
            />
            </div>
            <div className={`tab-content ${isOpen ? 'open' : ''}`}>
                {isOpen && (
                    <>
                        <div className="tab-buttons">
                            <button onClick={() => handleTabChange('kurz')} className={`tab-button ${activeTab === 'kurz' ? 'active' : ''}`}>{t('shortSummary')}</button>
                            <button onClick={() => handleTabChange('lang')} className={`tab-button ${activeTab === 'lang' ? 'active' : ''}`}>{t('longSummary')}</button>
                        </div>
                        {data[activeTab].summaryLoading ? (
                            <div>
                            <div className="speech-loading-message">
                                <div className="speech-loader"></div> {}
                                <div className="speech-loader-text">{t('loadingSummary')}</div>
                            </div>
                        </div>
                        ) : data[activeTab].speechLoading ? (
                            <div>
                                <div className="speech-loading-message">
                                    <div className="speech-loader"></div> {}
                                    <div className="speech-loader-text">{t('loadingAudio')}</div>
                                </div>
                            </div>
                        )
                        : data[activeTab].summaryError ? (
                            <p className="error-message">Fehler: {data[activeTab].summaryError}</p>
                        ) : 
                        (
                            <div className='summary-data'>
                            <audio controls key={data[activeTab].speechFile} className='audio-playbar'>
                                <source src={data[activeTab].speechFile} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                            <button 
                                onClick={toggleTextVisibility} 
                                className="show-text-icon"
                            >
                                <FontAwesomeIcon 
                                    icon={faFileAlt} 
                                />
                            </button>
                            {data[activeTab].summary && (
                                <button 
                                    onClick={refreshContent} 
                                    className="refresh-content"
                                >
                                    <FontAwesomeIcon 
                                        icon={faRotateRight} 
                                    />
                            </button>
                            )}                            
                            <div className={`data-content ${isVisible ? "data-content-visible" : ""}`} dangerouslySetInnerHTML={{ __html: data[activeTab].summary }} />
                        </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Expandable;
