import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Login from './Login';
import Expandable from './Expandable';
import ExpandableClient from './ExpandableClient'
import './App.css';
import logo from './Atlantic-white.svg';
import {
  faAngleDown, 
  faAngleUp, 
  faSignOutAlt,
  faCalendarAlt,
  faCalendar,
  faChartLine,
  faMoneyBillWave,
  faBusinessTime,
  faGlobeAmericas,
  faUsersLine,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  
  const handleLogin = (authStatus) => {
    setIsAuthenticated(authStatus);
  };

  const toggleExpandables = () => {
    setAllExpanded(!allExpanded);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };

  function downloadPDF() {
    const pdfUrl = "/Industrie_Report.pdf";
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'hosberg_ag_analytics.pdf';
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
  }

  const today = new Date().toLocaleDateString(i18n.language, {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });

  function logout() {
    i18n.changeLanguage('de');
    setIsAuthenticated(false);
  }

  return (
    <Router>
      <div className="parent-container">
        <Routes>
          <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
          <Route path="/" element={
            isAuthenticated ? (
              <div className='expandables-parent'>
                <div className='logout-container'>
                  <img src={logo} alt="login-logo" className='app-logo'/>
                  <FontAwesomeIcon icon={faSignOutAlt} onClick={logout} className="logout-icon" />
                </div>
                <div className='language-selection-container'>
                  <FontAwesomeIcon icon={faFilePdf} onClick={downloadPDF} className="pdf-icon" />
                  <select 
                    className='language-options' 
                    name="languages" 
                    id="language-select" 
                    onChange={handleLanguageChange}>
                    <option value="de">Deutsch</option>
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                  </select>
                </div>
                <h1 className="app-title">Early Lion AI</h1>
                <h2 className="app-sub-title">
                  {t('greeting')}
                </h2>
                <div className='open-all-button-container'>
                  <div className="date-container">{today}</div>
                  <button className='open-all-button' onClick={toggleExpandables}>
                    {allExpanded ? t('closeAll') : t('openAll')} <FontAwesomeIcon icon={allExpanded ? faAngleUp : faAngleDown} />
                  </button>
                </div>
                <Expandable 
                  title={t('monthlyReport')}
                  summaryApiUrl={`/api/summary?summary_type=monthly_income_summary&language=${i18n.language}`}
                  icon={faCalendarAlt}
                  expanded={allExpanded}
                  language={i18n.language}
                />
                 <Expandable 
                  title={t("yearlyReport")}
                  summaryApiUrl={`/api/summary?summary_type=yearly_income_summary&language=${i18n.language}`}
                  icon={faCalendar}
                  expanded={allExpanded}
                  language={i18n.language}
                />
                <Expandable 
                  title={t("bilance_kpi")}
                  summaryApiUrl={`/api/summary?summary_type=balance_kpis_summary&language=${i18n.language}`}
                  icon={faChartLine}
                  expanded={allExpanded}
                  language={i18n.language}
                />
                <Expandable 
                  title={t("netRevenue")}
                  summaryApiUrl={`/api/summary?summary_type=net_revenue_summary&language=${i18n.language}`}
                  icon={faMoneyBillWave}
                  expanded={allExpanded}
                  language={i18n.language}
                />
                <Expandable 
                  title={t("revenue")}
                  summaryApiUrl={`/api/summary?summary_type=revenue_summary&language=${i18n.language}`}
                  icon={faBusinessTime}
                  expanded={allExpanded}
                  language={i18n.language}
                />
                <Expandable 
                  title={t("regionAnalysis")}
                  summaryApiUrl={`/api/summary?summary_type=region_analysis_summary&language=${i18n.language}`}
                  icon={faGlobeAmericas}
                  expanded={allExpanded}
                  language={i18n.language}
                />
                <ExpandableClient 
                  title={t("myClients")}
                  summaryApiUrl={`/api/summary?summary_type=region_analysis_summary&language=${i18n.language}`}
                  icon={faUsersLine}
                  expanded={allExpanded}
                  language={i18n.language}
                />                    
              </div>
            ) : <Navigate to="/login" />
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
